import React from "react"

export default props => {
    return (
        <section id={props.sectionClass} className={props.sectionClass}>
            <div className="sectionBackground"></div>
            <div className={`sectionContent ${props.contentClasses}`}>
                {props.headerTitle && (
                    <header>
                        <div className="headerBackground"></div>
                        <h2>{props.headerTitle}</h2>
                    </header>
                )}
                {props.children}
            </div>
        </section>
    )
}
