import React from "react"
import { Link } from "gatsby"

export default () => {
    return (
        <footer>
            <div className="footerContent">
                <div className="copyright">&copy; {new Date().getFullYear()} Swift Walkies :: All Rights Reserved</div>
                <div className="links">
                    <Link to="/privacy">Privacy Policy</Link>
                    {/* <span>VAT Number: 123456789</span>
                    <span>Company Number: 123456789</span> */}
                </div>
            </div>
        </footer>
    )
}
