import React from "react"
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
} from "react-share"

import "../styles/share.scss"

const Share = ({ socialConfig }) => (
    <div className="post-social">
        <FacebookShareButton
            className="button facebook"
            url={socialConfig.config.url}
            quote={socialConfig.config.title}>
            <FacebookIcon size="48" />
        </FacebookShareButton>
        <TwitterShareButton
            className="button twitter"
            url={socialConfig.config.url}
            title={socialConfig.config.title}
            // via={socialConfig.twitterHandle.split("@").join("")}
            // hashtags={tags}
        >
            <TwitterIcon size="48" />
        </TwitterShareButton>
        <LinkedinShareButton
            className="button linkedin"
            url={socialConfig.config.url}
            title={socialConfig.config.title}>
            <LinkedinIcon size="48" />
        </LinkedinShareButton>
        <WhatsappShareButton
            className="button whatsapp"
            url={socialConfig.config.url}
            title={socialConfig.config.title}>
            <WhatsappIcon size="48" />
        </WhatsappShareButton>

        <EmailShareButton
            className="button email"
            url={socialConfig.config.url}
            subject={socialConfig.config.title}
            body="Check out Swift Walkies - ">
            <EmailIcon size="48" />
        </EmailShareButton>
    </div>
)

export default Share
