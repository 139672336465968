import React, { useEffect } from "react"
import { Link } from "gatsby"

import { headerY, headerSize } from "../js/header"
import HeaderNav from "./headerNav"
import HeaderMobileNav from "./headerMobileNav"

import Logo from "../imgs/logo.svg"

export default () => {
    useEffect(() => {
        headerY()
        window.addEventListener("scroll", () => {
            headerSize()
        })
    })

    return (
        <header id="header">
            <div>
                <div className="headerLogo">
                    <Link to="/">
                        <img id="logoImg" src={Logo} alt="Swift Walkies Logo In Yellow" title="Swift Walkies" />
                    </Link>
                </div>
                <HeaderNav id="mainNav" />
                <HeaderMobileNav id="mobileNavHandle" />
            </div>
        </header>
    )
}
