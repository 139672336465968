import React from "react"

import { mobileNav } from "../js/header"
import HeaderNav from "./headerNav"

export default props => {
    return (
        <div id={props.id}>
            <button
                id="hamburger"
                className="hamburger"
                type="button"
                name="Mobile Navigation Menu Button"
                onClick={() => mobileNav()}>
                <span className="hamburgerInner"></span>
            </button>

            <div className="mobileNav">
                <HeaderNav id="mobNav" />
            </div>
        </div>
    )
}
