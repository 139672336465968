const initPosition = 1000
let headerHeight = 450
let scrollPosition = initPosition

export function headerY() {
    let topPosition = Math.round(document.getElementsByTagName("main")[0].getBoundingClientRect().top)
    topPosition = topPosition < 0 ? Math.round(document.getElementsByTagName("main")[0].getBoundingClientRect().top) : 0
    const headerPosition = Math.round(document.getElementsByTagName("h1")[0].getBoundingClientRect().top) - 100
    headerHeight = headerPosition - topPosition
}

export function headerSize() {
    const header = document.getElementsByTagName("header")[0]
    const scrollArrow = document.getElementById("scrollArrow")

    if (window.scrollY <= 0) {
        header.classList.remove("headerSmall", "headerHide")
        scrollArrow && scrollArrow.classList.remove("hidden")
    } else if (window.scrollY > headerHeight) {
        header.classList.add("headerSmall")
        scrollArrow && scrollArrow.classList.add("hidden")

        if (window.scrollY > scrollPosition) {
            header.classList.add("headerHide")
        } else {
            header.classList.remove("headerHide")
        }

        if (window.scrollY > initPosition) {
            scrollPosition = window.scrollY
        }
    } else {
        header.classList.remove("headerSmall")
        scrollArrow && scrollArrow.classList.remove("hidden")
    }
}

export function mobileNav() {
    document.getElementById("hamburger").classList.toggle("isActive")
    document.getElementsByTagName("body")[0].classList.toggle("navActive")
}
