import React from "react"
import { Link } from "gatsby"

export default props => {
    return (
        <nav id={props.id}>
            <div>
                <Link to="/#services">Services</Link>
            </div>
            <div>
                <Link to="/#faq">FAQ</Link>
            </div>
            <div>
                <Link to="/#contact">Contact</Link>
            </div>
        </nav>
    )
}
