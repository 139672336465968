import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CookieConsent from "react-cookie-consent"

import Header from "./header"
import Footer from "./footer"
import Share from "./share"

export default ({ children }) => {
    const data = useStaticQuery(graphql`
        query ShareQuery {
            site {
                siteMetadata {
                    author
                    description
                    image
                    siteUrl
                    title
                }
            }
        }
    `)

    return (
        <div id="layout">
            <Header />
            <main>{children}</main>
            <Footer />

            <Share
                socialConfig={{
                    // twitterHandle,
                    config: {
                        url: data.site.siteMetadata.siteUrl,
                        title: data.site.siteMetadata.title,
                    },
                }}
                // tags={tags}
            />

            <CookieConsent
                enableDeclineButton
                flipButtons
                location="bottom"
                buttonText="Accept"
                declineButtonText="Decline"
                cookieName="gatsby-gdpr-google-analytics"
                style={{ background: "#21523b" }}
                buttonClasses="cookieAccept"
                declineButtonClasses="cookieDecline">
                We use cookies to give you the best experience possible. By continuing to use this site you agree to our
                use of cookies.
            </CookieConsent>

            <svg width="100%" height="100%">
                <defs>
                    <clipPath id="navClip" transform="scale(0.00125, 0.01)" clipPathUnits="objectBoundingBox">
                        <path d="M0,0l800,0l0,67.49c0,0 -67.871,-19.318 -143.211,-17.49c-65.34,1.585 -273.489,34.826 -394.231,46.412c-139.02,13.341 -262.558,-25.184 -262.558,-25.184l0,-71.228Z" />
                    </clipPath>
                </defs>
            </svg>

            <svg width="100%" height="100%">
                <defs>
                    <clipPath id="sectionClip1" transform="scale(0.00125, 0.00125)" clipPathUnits="objectBoundingBox">
                        <path d="M0,800l800,0l0,-778.056c0,0 -65,30.24 -140.34,28.287c-65.34,-1.694 -276.36,-34.017 -397.102,-46.398c-139.02,-14.254 -262.558,15.835 -262.558,15.835l0,780.332Z" />
                    </clipPath>
                </defs>
            </svg>

            <svg width="100%" height="100%">
                <defs>
                    <clipPath id="sectionClip2" transform="scale(0.00125, 0.00125)" clipPathUnits="objectBoundingBox">
                        <path d="M800,800l-800,0l0,-793.3c0,0 66.178,32.762 141.518,30.809c65.34,-1.694 203.8,-18.619 324.542,-31c139.02,-14.254 333.94,31.212 333.94,31.212l0,762.28l0,-0.001Z" />
                    </clipPath>
                </defs>
            </svg>

            <svg width="100%" height="100%">
                <defs>
                    <clipPath
                        id="mobileSectionClip1"
                        transform="scale(0.00333, 0.00125)"
                        clipPathUnits="objectBoundingBox">
                        <path d="M0,800l300,0l0,-794.357c0,0 -51.063,15.516 -76.492,15.36c-24.56,-0.152 -78.815,-2.406 -124.038,-14.985c-46.811,-13.021 -99.47,6.833 -99.47,6.833l0,787.149Z" />
                    </clipPath>
                </defs>
            </svg>

            <svg width="100%" height="100%">
                <defs>
                    <clipPath
                        id="mobileSectionClip2"
                        transform="scale(0.00333, 0.00125)"
                        clipPathUnits="objectBoundingBox">
                        <path d="M300,800l-300,0l0,-794.869c0,0 26.965,12.929 55.075,14.668c24.514,1.516 79.25,-2.042 124.529,-14.423c52.132,-14.254 120.396,16.222 120.396,16.222l0,778.403l0,-0.001Z" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}
